import axios from 'axios'; // 引入axios

axios.defaults.headers = [];
axios.defaults.withCredentials = true;
/**
 * 生成axios实例
 */
const http = axios.create({
    withCredentials: true, //跨站点默认携带Session数据
    headers: {
        'Content-Type': 'application/json;charset=utf-8'
    }
});
//请求中加入token
http.interceptors.request.use(cfg => {
    cfg.headers.token = sessionStorage.getItem('mp_token');
  return cfg;
})


/**
 * 封装Post方法
 * @param param
 * {
 *    url: 请求路径
 *    type: 'APP'或'PC'或空，空默认为'PC'
 *    hideMask: true 或 false，是否显示加载遮罩提示框
 * 
 * }
 * @returns {Promise}
 */
export function post(param){
    const me = this;
    if(!param || !param.url){
        me.$message.error('请传入请求参数或地址');
    }
    return new Promise((resolve, reject) => {
        !param.hideMask && showLoadMask(me);
        http({
            method:'post',
            baseURL: window.cfg.BASE_URL,
            // timeout: me.$global.timeout ? me.$global.timeout : 100000,
            url: param.url,
            data: param.params,
        }).then(rsp => {
            closeLoadMask();
            let res = rsp.data;
            if (res.success) { //成功
                sessionStorage.removeItem('exception_code');
                resolve(res);
            }else{
                disposeRet(me, res.exception, param);
                resolve(res);
            }
        }).catch(e => {
            showError(me, e, param);
            reject(e)
        })
    });
}

//控制显示加载遮罩
let LM = null;
function showLoadMask(dom){
    LM = dom.$loading({
      lock: true,
      text: '网络请求中，请稍候……',
      spinner: 'el-icon-loading'
    });
}
//关闭加载遮罩
function closeLoadMask(){
    LM && LM.close();
}

/**
 * 
 * @returns 显示异常信息
 */
function disposeRet(dom, ecp, param){
    if(ecp){
        if(ecp.url && ecp.url == "/login"){
            dom.$router.push('/mng/logon');
        }else{
            param.type != 'APP' && ecp.url && dom.$router.push(ecp.url);
        }
        
    }else{
      dom.$message.error('系统发送错误，请联系管理员');
    }
}

/**
 * 显示错误消息
 */
//显示错误消息
function showError(dom, err, param){
  closeLoadMask();
  if(!err.response && err.message == 'timeout of 10000ms exceeded'){
    dom.$message({
      message:'网络请求超时，请检查网络',
      type:'error',
      duration:6000,
      showClose:true
    });
  }else if(param.type && param.type == 'APP'){
    dom.$message.error('网络请求失败，请联系管理员');
  }else{
    console.log(err);
    dom.$router.push('/error');
  }
}
