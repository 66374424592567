import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

const routes = [{
    path: '/',
    component: () => import('@/components/portal/portal.vue')
},{
    path: '/mng/logon',
    component: () => import('@/components/manager/logon/logon.vue')
},{
    path: '/mng/main',
    component: () => import('@/components/manager/home/home.vue')
},{
    path: '/h5/news/:key/info',
    component: () => import('@/components/h5/news/news.vue')
}]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
