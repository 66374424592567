<template>
  <router-view  id="app" class="mp_body"/>
</template>

<style>

* {
    margin: 0px;
    padding: 0px;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}
.mp_body{
    widows: 100%;
    height: 100vh;
    overflow: hidden;
}
</style>