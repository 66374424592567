import { createApp } from 'vue';



import App from './App.vue';
const app = createApp(App);

//引入路由
import router from './router';
app.use(router);

// 引入ElementPlus
import ElementPlus from 'element-plus';
app.use(ElementPlus, {
    locale: zhCn
});
import 'element-plus/dist/index.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
// 引入ElementPlus图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

// 引入axios
import axios from  'axios'
axios.defaults.baseURL = window.cfg.BASE_URL;
app.config.globalProperties.$axios = axios;
// 引入自定义封装的网络请求
import {post} from './config/axios/axios';
app.config.globalProperties.$post = post; // 全局定义axios的post方法

// 引入通用样式
import './config/css/common.css';


app.mount('#app');

